import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import { Link } from "gatsby"

const AboutMePage = props => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="I started coding my first program in 2013 with hpage.com. I was very happy after adding a colorful cursor and snowfall effect on my website using JavaScript"
        />
        <title>
          About me - Kalaivanan - Professional front-end/web engineer
        </title>
      </Helmet>
      <div className="about-me page">
        <h1>About Me</h1>
        <span className="break-line" />
        <div className="row pt-5">
          <div className="col-12">
            <p>
              Hello, I am Kalaivanan. I started coding my first program in 2013
              with hpage.com (simple HTML). I was very happy after adding a
              colorful cursor and snowfall effect on my website using JavaScript
              widget
              <span role="img" aria-label="Smiling Face With Halo">
                &#128519;.
              </span>
              Thanks to 24work.blogspot.com for the cool widgets.
            </p>

            <p>
              In 2015, I started programming in PHP, HTML, CSS and small amount
              of jQuery and started understanding the web. Since then, I started
              working as freelancer on Web Development and Search Engine
              Optimization (SEO) and learned a lot in the web development
              domain. Laravel and CodeIgniter are my favorite PHP frameworks
            </p>

            <p>
              In 2016, started my professional career as a Full Stack Engineer
              (PHP, HTML, CSS, jQuery). Admired with the trending technology and
              I was continuously watching that space to learn more. In Oct 2018,
              started working as a complete Frontend Engineer and turned my eye
              into React. Since then React is my favorite{" "}
              <span role="img" aria-label="Growing Heart">
                &#128151;
              </span>{" "}
              framework and I'm working on it till date.
            </p>

            <p>
              Most of the time on Twitter and many times running behind some
              tech buzz word. Apart from this, Cricket and watching movies are
              my hobbies
            </p>
            <Link className="btn btn-outline-primary mt-3" to="/skills">
              My Skills
            </Link>
          </div>
          <div className="col-4"></div>
        </div>
      </div>
    </Layout>
  )
}

export default AboutMePage
